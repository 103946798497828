<template>
  <v-card>
    <v-toolbar class="px-4" color="transparent" flat height="48">
      <v-toolbar-title class="body-1 secondary--text font-weight-bold">SFTP</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-card-text class="px-0">
      <v-list class="v-list-form-extended pt-3 pb-1 px-4 transparent">
        <v-list-item>
          <v-list-item-title>Habilitar</v-list-item-title>
          <v-list-item-subtitle><v-switch class="mt-n4" v-model="enable" color="success" style="position:absolute" /></v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Llave ssh</v-list-item-title>
          <v-list-item-subtitle class="my-1">
            <v-col cols="6">
              <v-file-input placeholder="Haz click para seleccionar o arrastra el archivo aquí" outlined dense hide-details></v-file-input>
            </v-col>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Puerto</v-list-item-title>
          <v-list-item-subtitle class="my-1">
            <v-col cols="4">
              <v-text-field class="icon-field" v-model="port" outlined hide-details required single-line dense maxlength="56" data-action="auto-focus" />
            </v-col>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Usuario</v-list-item-title>
          <v-list-item-subtitle class="my-1">
            <v-col cols="4">
              <v-text-field class="icon-field" v-model="user" placeholder="Nombre usuario" outlined hide-details required single-line dense maxlength="56" data-action="auto-focus" />
            </v-col>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-8 py-2">
      <v-spacer />
      <v-btn class="px-4" outlined>Cancelar</v-btn>
      <v-btn class="px-4" color="primary">Guardar cambios</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      enable: false,
      token: [],
      patchLoader: false,
      patchLoaderToken: false,
      modalPatchPassword: false,
      show: false,
      show1: false,
      show2: false,
      showToken: false,
      port: '22',
      user: ''
    }
  },
  computed: mapState({
    tokensList: state => (((state.accounts.tokensList || [])[0] || {}).key || '')
  }),
  created () {
    this.$store.dispatch('accounts/LIST', {
      resource: 'accounts'
    })
    this.$store.dispatch('accounts/LIST', {
      resource: 'tokens'
    })
  },
  methods: {
    patchTokens () {
      this.$dialog.warning({
        title: 'Actualizar token',
        text: `
          <span class="d-block">Al confirmar, el token existente se eliminará y se creará uno nuevo.</span>
          <span class="d-block my-4 font-weight-medium">Recuerda actualizar el token usado en tus integraciones.</span>
        `,
        actions: [
          {
            text: 'Continuar',
            color: 'primary',
            handle: () => {
              this.patchLoaderToken = true
              this.$store.dispatch('accounts/CREATE', {
                resource: 'tokens',
                payload: {}
              })
              .then(() => {
                this.$store.dispatch('accounts/LIST', {
                  resource: 'tokens'
                })
                this.$dialog.message.info('Tu token ha actualizado')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
              .finally(() => {
                this.patchLoaderToken = false
              })
            }
          }
        ]
      })
    }
  }
}
</script>